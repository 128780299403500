import React from 'react';
import Layout from '../components/layout';
// import LanguageMenu from '../components/i18n/languageMenu'
// import {useTranslation} from "react-i18next"


import { graphql } from "gatsby"
import Img from "gatsby-image"

const sections = [
    {id: 'top', name: 'Intro', icon: 'fa-home'},
    {id: 'portfolio', name: 'Portfolio', icon: 'fa-th'},
    {id: 'about', name: 'About Me', icon: 'fa-user'},
    {id: 'contact', name: 'Contact', icon: 'fa-envelope'},
];

const IndexPage = ({ data }) => {
    // const {t} = useTranslation()


    return (
        <Layout>
            <div id="main">
                <section id="top" className="one dark cover">
                    <div className="container">
                        <header>
                            <h1 id="title">
                                <p>Little Cottage House</p>
                                <p>Pokrzywnik20</p>

                            </h1>
                        </header>

                    </div>
                </section>

                <section>
                    <div className="container">
                        <header>
                            <h3 id="main-motto">If you are looking for silence and nature, you will find it here.</h3>
                            {/*{t('site.footer')}*/}
                        </header>
                        <p>
                            {/*<LanguageMenu></LanguageMenu>*/}
                            It would be our honour to invite you to our modest cottage house. With our old-timey
                            atmosphere you should feel at home while cozying up in one of our couches whilst enjoying
                            the company of the fireplace.
                        </p>
                        <p>
                            It was built around the end of the 19th century and redecorated in 2017, however all the
                            rooms have kept their initial design in order to maintain the vintage atmosphere.
                            If you look outside the window you can see nature’s greatest gifts, such as forests,
                            mountains, rivers and a vast animal diversity.
                            Inside the house you can find all necessary commodities.
                        </p>
                        <p>
                            The furniture you can find in the house is made out of sturdy wood from the 19th century.
                            The decorations and furniture of the house are wooden with modern elements.

                        </p>
                        <div className="row"></div>
                        <div className="row">
                            <div className="col-4 col-12-mobile">
                                <article className="item">
                                    <a href={data.image1.childImageSharp.fluid.src} className="image fit">
                                        <Img
                                            fluid={data.image1.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3>On the ground floor you can find your very own cozy space,
                                            containing a fireplace, comfortable sofas and a unique atmosphere.</h3>
                                    </header>
                                </article>
                                <article className="item">
                                    <a href={data.image2.childImageSharp.fluid.src} className="image fit">
                                        <Img
                                            fluid={data.image2.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3>On the first floor there are multiple comfortable sleeping options:
                                            2 bedrooms; one with 2 beds.
                                            the option of extra sleeping places on the entresol. </h3>
                                    </header>
                                </article>
                            </div>
                            <div className="col-4 col-12-mobile">
                                <article className="item">
                                    <a href={data.image3.childImageSharp.fluid.src} className="image fit">
                                        <Img
                                            fluid={data.image3.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3>It’s also very likely that you will meet cows from our neighbour’s
                                            pasture. You can also get natural local produce from our neighbours such as
                                            eggs and milk.</h3>
                                    </header>
                                </article>
                                <article className="item">
                                    <a href={data.image5.childImageSharp.fluid.src} className="image fit">
                                        <Img
                                            fluid={data.image5.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3>The vintage room contains 3 beds.
                                            The height of the vintage room has the original 19th century dimensions
                                            (1.75 meters). </h3>
                                    </header>
                                </article>


                            </div>
                            <div className="col-4 col-12-mobile">
                                <article className="item">
                                    <a href={data.image6.childImageSharp.fluid.src} className="image fit">
                                        <Img
                                            fluid={data.image6.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3>You will also find a conveniently sized kitchen counter which includes
                                            everything you need to prepare a meal. </h3>
                                    </header>
                                </article>
                                <article className="item">
                                    <a href={data.image7.childImageSharp.fluid.src} className="image fit">
                                        <Img
                                            fluid={data.image7.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3>Near the stairs is a spacious bathroom with a wood stove that warms the
                                            whole room.</h3>
                                    </header>
                                </article>
                            </div>

                            <p>
                                The garden in front of the house is large, full of trees and bushes with a small
                                vegetable garden. There is also a local market with a bigger selection of natural local
                                products
                                such as bread, cow cheese, goat cheese, sheep cheese, sausages, ham, honey, cookies,
                                fresh vegetables and fruits.

                            </p>
                        </div>
                        <div className="row" >
                            <div className="col-4 col-12-mobile">
                                <article className="item">
                                    <a href={data.image8.childImageSharp.fluid.src} className="image fit">
                                        <Img
                                            fluid={data.image8.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3>Fresh morning</h3>
                                    </header>
                                </article>
                            </div>
                            <div className="col-4 col-12-mobile">
                                <article className="item">
                                    <a href={data.image9.childImageSharp.fluid.src}  className="image fit">
                                        <Img
                                            fluid={data.image9.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3> Preparing for winter</h3>
                                    </header>
                                </article>


                            </div>
                            <div className="col-4 col-12-mobile">
                                <article className="item">
                                    <a href={data.image10.childImageSharp.fluid.src}  className="image fit">
                                        <Img
                                            fluid={data.image10.childImageSharp.fluid}
                                        />
                                    </a>
                                    <header>
                                        <h3>Take the rest</h3>
                                    </header>
                                </article>

                            </div>

                        </div>
                    </div>
                </section>

                <section>
                    <div className="container">
                        <header>
                            <h2>Surroundings</h2>
                        </header>

                        <a href={data.image11.childImageSharp.fluid.src}  className="image featured">
                            <Img
                                fluid={data.image11.childImageSharp.fluid}
                            />
                        </a>

                        <p>
                            Nearby the house, you will find: a Wild Canyon (10 minutes by foot), Capitan’s Bridge (30
                            minutes by foot),
                            a mountain stream (20 minutes by foot), the “Zapora
                            Pilchowicka” dam (30 minutes by foot), beautiful view of the “Karkonosze” mountain side (2
                            minutes by foot).
                            Many touristic routes cross through this village (e.g. Saint Jacob Road).
                            If you want to spend your time actively, you can go horse riding or canoeing in the nearby
                            villages .
                            Pokrzywnik is located in the neighbourhood of Dolina Pałaców i Ogrodów (Valley of Palaces
                            and Gardens).
                            15 minutes away from the house, you can find a palace built in 1838 and a
                            renaissance court.
                            From every angle you are able to see the beautiful panorama of the mountains.
                            Of course, the best activity to do here is to get much needed rest and relaxation.
                        </p>
                        <p id="endSignature">
                            Try live like in the good old days.
                        </p>
                    </div>
                </section>

                <section id="contact" className="four">
                    <div className="container">
                        <header>
                            <h2>Contact</h2>
                        </header>

                        <p>
                            <a href="mailto:kontakt@pokrzywnik20.pl">kontakt@pokrzywnik20.pl</a>
                        </p>
                    </div>
                </section>
            </div>

        </Layout>
    );
}

export default IndexPage;

export const query = graphql`
  query MyQuery {
    image1 : file(relativePath: { eq: "assets/images/10_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2 : file(relativePath: { eq: "assets/images/3_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3 : file(relativePath: { eq: "assets/images/9_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image5 : file(relativePath: { eq: "assets/images/2_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image6 : file(relativePath: { eq: "assets/images/4_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image7 : file(relativePath: { eq: "assets/images/1_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image8 : file(relativePath: { eq: "assets/images/12_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image9 : file(relativePath: { eq: "assets/images/11_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image10 : file(relativePath: { eq: "assets/images/13_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    image11 : file(relativePath: { eq: "assets/images/7_.jpeg" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
